




















import Vue from "vue";
import ScatterChart from "@/components/ScatterChart.vue";
import Component from "vue-class-component";
import { DataFrame } from "dataframe-js";

interface RegionDayStats {
  data: string;
  stato: string;
  codice_regione: number;
  denominazione_regione: string;
  lat: number;
  long: number;
  ricoverati_con_sintomi: number;
  terapia_intensiva: number;
  totale_ospedalizzati: number;
  isolamento_domiciliare: number;
  totale_positivi: number;
  variazione_totale_positivi: number;
  nuovi_positivi: number;
  dimessi_guariti: number;
  deceduti: number;
  totale_casi: number;
  tamponi: number;
}

@Component({
  components: {
    ScatterChart,
  },
})
export default class Sorveglianza extends Vue {
  loaded = false;
  chartdata = {};
  options = {};
  yLine = 0;
  xLine = 0;

  async mounted(): Promise<void> {
    this.loaded = false;

    if (this.$store.state.regionsData.length) {
      this.init(this.$store.state.regionsData);
      return;
    }

    fetch(
      "https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/dati-json/dpc-covid19-ita-regioni.json"
    )
      .then((res) => res.json())
      .then((proCivData: RegionDayStats[]) => {
        this.$store.state.regionsData = proCivData;
        this.init(proCivData);
      });
  }

  private init(proCivData: RegionDayStats[]): void {
    const lastDate = proCivData[proCivData.length - 1].data;
    const threshold = new Date(lastDate);
    threshold.setDate(threshold.getDate() - 7);

    const columns = Object.keys(proCivData[0]);

    const dataFrame = new DataFrame(proCivData, columns).filter((row) => {
      const date = new Date(row.get("data").substr(0, 10));

      return date > threshold;
    });

    this.plot(dataFrame);
  }

  plot(dataFrame: DataFrame): void {
    const labels: string[][] = [[], []];

    const populations: Record<string, number> = {
      Abruzzo: 1305770,
      Basilicata: 556934,
      Calabria: 1924701,
      Campania: 5785861,
      "Emilia-Romagna": 4467118,
      "Friuli Venezia Giulia": 1211357,
      Lazio: 5865544,
      Liguria: 1543127,
      Lombardia: 10103969,
      Marche: 1518400,
      Molise: 302265,
      "P.A. Bolzano": 533373,
      "P.A. Trento": 541380,
      Piemonte: 4341375,
      Puglia: 4008296,
      Sardegna: 1630474,
      Sicilia: 4968410,
      Toscana: 3722729,
      Umbria: 880285,
      "Valle d'Aosta": 125501,
      Veneto: 4907704,
    };

    const dfRegions = dataFrame.groupBy("denominazione_regione");

    const dfMeanNew = dfRegions
      .aggregate((group) => {
        return group.stat.mean("nuovi_positivi");
      }, "media_nuovi_positivi")
      .map((row) => {
        const population = populations[row.get("denominazione_regione")];
        const popNew = (row.get("media_nuovi_positivi") / population) * 100000;
        row = row.set("media_nuovi_positivi_pop", popNew);

        return row.set(
          "media_nuovi_positivi_pop_round",
          Math.round(popNew * 100) / 100
        );
      });

    let firstDayTests = 0;
    const dfMeanTests = dfRegions.map((row, idx) => {
      const population = populations[row.get("denominazione_regione")];

      if (idx === 0) {
        firstDayTests = row.get("tamponi");
      }
      if (idx === 6) {
        const weekTests = row.get("tamponi") - firstDayTests;
        const popTests = (weekTests / population) * 1000;
        return row.set("tamponi_settimana", Math.round(popTests * 100) / 100);
      }
    });

    const dfToPlot = dfMeanNew.innerJoin(dfMeanTests, "denominazione_regione");

    const trend: Record<string, unknown>[] = dfToPlot.reduce((acc, row) => {
      acc.push({
        label: row.get("denominazione_regione"),
        x: row.get("media_nuovi_positivi_pop_round"),
        y: row.get("tamponi_settimana"),
      });

      return acc;
    }, []);

    labels[1].push("Media nazionale");

    const mean = {
      label: "Media nazionale",
      x:
        Math.round(dfToPlot.stat.mean("media_nuovi_positivi_pop_round") * 100) /
        100,
      y: Math.round(dfToPlot.stat.mean("tamponi_settimana") * 100) / 100,
    };

    this.yLine = mean.y;
    this.xLine = mean.x;

    this.chartdata = {
      labels,
      datasets: [
        {
          label: "Tamponi e casi sulla popolazione",
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: trend,
        },
        {
          label: "Media nazionale",
          backgroundColor: "orange",
          borderColor: "orange",
          fill: false,
          data: [mean],
        },
      ],
    };

    this.loaded = true;
  }
}








import Vue from "vue";
import { Scatter } from "vue-chartjs";
import Component from "vue-class-component";
import { Prop, Watch, Ref } from "vue-property-decorator";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import { Align } from "chartjs-plugin-datalabels/types/options";
import { ChartPluginsOptions } from "chart.js";

@Component({
  components: {
    ScatterChartJs: Scatter,
  },
})
export default class ScatterChart extends Vue {
  @Prop({ default: {} }) chartdata!: Record<string, []>;
  @Prop({ default: 300 }) height!: number;
  @Prop({ default: 0 }) yLine!: number;
  @Prop({ default: 0 }) xLine!: number;

  @Ref() chart!: Scatter;

  @Watch("chartdata")
  onCartDataChanged(): void {
    this.refreshChart();
  }

  mounted(): void {
    this.refreshChart();
  }

  refreshChart(): void {
    this.chart.renderChart(this.chartdata, this.getOptions());
  }

  changeScale(): void {
    this.refreshChart();
  }

  getOptions(): ChartPluginsOptions {
    return {
      plugins: {
        datalabels: {
          align: "top" as Align,
          formatter: (value: Record<string, string>) => {
            return value.label;
          },
        },
      },
      annotation: {
        annotations: [
          {
            type: "line",
            drawTime: "beforeDatasetsDraw",
            mode: "horizontal",
            scaleID: "y-axis-1",
            value: this.yLine,
            borderColor: "orange",
            borderWidth: 2,
          },
          {
            type: "line",
            drawTime: "beforeDatasetsDraw",
            mode: "vertical",
            scaleID: "x-axis-1",
            value: this.xLine,
            borderColor: "orange",
            borderWidth: 2,
          },
          {
            type: "line",
            drawTime: "beforeDatasetsDraw",
            mode: "vertical",
            scaleID: "x-axis-1",
            value: 250 / 7,
            borderColor: "red",
            borderWidth: 1,
          },
          {
            type: "line",
            drawTime: "beforeDatasetsDraw",
            mode: "vertical",
            scaleID: "x-axis-1",
            value: 50 / 7,
            borderColor: "green",
            borderWidth: 1,
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: (
            tooltipItem: Record<string, number>[],
            data: Record<string, []>
          ) => {
            const item = tooltipItem[0];
            return data.labels[item.datasetIndex][item.index];
          },
          label: (tooltipItem: Record<string, string>) => {
            return `Incidenza: ${tooltipItem.xLabel}, tamponi: ${tooltipItem.yLabel}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Incidenza (numero nuovi casi per 100.000 abitanti)",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Tamponi effettuati (per 1.000 abitanti)",
            },
          },
        ],
      },
    };
  }
}
